import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GeneratePdf from "../../../Utils/GeneratePdf";
import LetterHead from "../../../components/LetterHead";

const IncomeStatement = () => {
	const [FromDate, setFromDate] = useState(
		new Date(
			new Date().getFullYear(),
			new Date().getMonth(),
			1
		).toLocaleDateString("en-CA")
	);
	const [ToDate, setToDate] = useState(
		new Date(
			new Date().getFullYear(),
			new Date().getMonth() + 1,
			0
		).toLocaleDateString("en-CA")
	);

	const [Summary, setSummary] = useState("custom");
	const [FirstDayUnix, setFirstDayUnix] = useState(
		new Date(FromDate).getTime()
	);
	const [LastDayUnix, setLastDayUnix] = useState(
		new Date(ToDate).setHours(23, 59, 59, 999)
	);

	// Recalculate timestamps when FromDate or ToDate changes
	useEffect(() => {
		setFirstDayUnix(new Date(FromDate).getTime());
		setLastDayUnix(new Date(ToDate).setHours(23, 59, 59, 999));
	}, [FromDate, ToDate]);

	const Payments = useSelector((state) => state.payment.payments);
	const Invoices = useSelector((state) => state.invoice.invoices);

	const Expenses = useSelector((state) => state.expense.expenses).filter(
		(item) =>
			parseInt(item.deleted) === 0 &&
			item.linker >= FirstDayUnix &&
			item.linker <= LastDayUnix
	);

	const PayrollCats = useSelector(
		(state) => state.payrollCat.payrollCats
	).filter((item) => parseInt(item.deleted) === 0);

	const PayrollItems = useSelector(
		(state) => state.payrollItem.payrollItems
	).filter((item) => parseInt(item.deleted) === 0);

	const PayrollEntries = useSelector(
		(state) => state.payrollEntry.payrollEntries
	).filter(
		(item) =>
			parseInt(item.deleted) === 0 &&
			item.month >= new Date(FirstDayUnix).toISOString().slice(0, 7) &&
			item.month <= new Date(LastDayUnix).toISOString().slice(0, 7)
	);

	const FilteredPayrollEntries = PayrollEntries.filter((item) =>
		PayrollItems.some(
			(payrollItem) =>
				parseInt(payrollItem.linker) === parseInt(item.itemLinker) &&
				PayrollCats.some(
					(payrollCat) =>
						payrollCat.linker === payrollItem.catLinker &&
						["Income", "Allowance"].includes(payrollCat.type)
				)
		)
	);

	// Filter Invoice transactions within the date range
	const filteredInvoices = Invoices.filter(
		(item) => item.linker >= FirstDayUnix && parseInt(item.deleted) === 0
	);

	// Filter Payment transactions within the date range
	const filteredPayments = Payments.filter(
		(item) =>
			item.linker >= FirstDayUnix &&
			item.linker <= LastDayUnix &&
			parseInt(item.deleted) === 0
	);

	const GetCurrentWeek = () => {
		const currentDate = new Date();
		const dayOfWeek = currentDate.getDay() || 7; // Set Sunday as 7 for ISO week
		currentDate.setDate(currentDate.getDate() + 4 - dayOfWeek); // Shift date to Thursday in the current week
		const yearStart = new Date(currentDate.getFullYear(), 0, 1);
		const weekNumber = Math.ceil(
			((currentDate - yearStart) / 86400000 + 1) / 7
		);

		return `${currentDate.getFullYear()}-W${weekNumber
			.toString()
			.padStart(2, "0")}`;
	};

	const GetCurrentMonth = () => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
		return `${year}-${month}`;
	};

	const GetWeekTimestamps = (weekString) => {
		const [year, week] = weekString.split("-W").map(Number);

		// Get the first day of the year
		const firstDayOfYear = new Date(year, 0, 1);

		// Calculate the day of the week for the first day of the year (0 = Sunday, 6 = Saturday)
		const dayOfWeek = firstDayOfYear.getDay() || 7; // Adjust so Sunday is 7

		// Get the first Monday of the year
		const dayOffset = dayOfWeek <= 4 ? dayOfWeek - 1 : dayOfWeek - 8;
		const firstMonday = new Date(
			firstDayOfYear.setDate(firstDayOfYear.getDate() - dayOffset)
		);

		// Calculate the start of the given week
		const weekStart = new Date(
			firstMonday.setDate(firstMonday.getDate() + (week - 1) * 7)
		);

		// Calculate the end of the week (Sunday)
		const weekEnd = new Date(weekStart);
		weekEnd.setDate(weekStart.getDate() + 6); // Move to Sunday

		// Convert to Unix timestamps in milliseconds
		const startTimestamp = weekStart.getTime();
		const endTimestamp = weekEnd.getTime() + 24 * 60 * 60 * 1000 - 1; // End of the day on Sunday
		setFirstDayUnix(startTimestamp);
		setLastDayUnix(endTimestamp);
		return { startTimestamp, endTimestamp };
	};

	const GetMonthTimestamps = (monthString) => {
		const [year, month] = monthString.split("-").map(Number);

		// Get the first day of the month (month is 0-indexed)
		const firstDayOfMonth = new Date(year, month - 1, 1);

		// Get the first day of the next month
		const firstDayOfNextMonth = new Date(year, month, 1);

		// The end of the month is the last millisecond of the previous day of the next month
		const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1);

		// Convert to Unix timestamps in milliseconds
		const startTimestamp = firstDayOfMonth.getTime(); // Start of the month
		const endTimestamp = lastDayOfMonth.getTime(); // End of the month (last millisecond)
		setFirstDayUnix(startTimestamp);
		setLastDayUnix(endTimestamp);
		return { startTimestamp, endTimestamp };
	};

	const GetYearTimestamps = (yearString) => {
		const year = Number(yearString);

		// Get the first day of the year (January 1st, 00:00)
		const firstDayOfYear = new Date(year, 0, 1);

		// Get the first day of the next year
		const firstDayOfNextYear = new Date(year + 1, 0, 1);

		// The end of the year is the last millisecond of December 31st
		const lastDayOfYear = new Date(firstDayOfNextYear.getTime() - 1);

		// Convert to Unix timestamps in milliseconds
		const startTimestamp = firstDayOfYear.getTime(); // Start of the year
		const endTimestamp = lastDayOfYear.getTime(); // End of the year (last millisecond)

		setFirstDayUnix(startTimestamp);
		setLastDayUnix(endTimestamp);

		return { startTimestamp, endTimestamp };
	};

	const [Week, setWeek] = useState(GetCurrentWeek());
	const [Month, setMonth] = useState(GetCurrentMonth());
	const CurrentYear = new Date().getFullYear();
	const [Year, setYear] = useState(CurrentYear);
	const Years = Array.from({ length: 100 }, (v, i) => CurrentYear - i);

	return (
		<div>
			<div className="row justify-content-center">
				<div className="col-md-8 col-lg-7 col-xl-6 card m-1" id="Profit&Loss">
					<LetterHead></LetterHead>
					<p className="text-center">
						<strong>Profit & Loss </strong>
					</p>
					<div className="d-flex justify-content-around card-header">
						<div>
							<strong>Type : </strong>
							<select
								onChange={(e) => {
									setSummary(e.target.value);
									return e.target.value === "weekly"
										? GetWeekTimestamps(Week)
										: e.target.value === "monthly"
										? GetMonthTimestamps(Month)
										: e.target.value === "annual"
										? GetYearTimestamps(Year)
										: (setFirstDayUnix(new Date(FromDate).getTime()),
										  setLastDayUnix(new Date(ToDate).getTime()));
								}}
								value={Summary}
								className="rounded "
							>
								<option value={"custom"}>Custom Sum</option>
								<option value={"weekly"}>Weekly Sum</option>
								<option value={"monthly"}>Monthly Sum</option>
								<option value={"annual"}>Annual Sum</option>
							</select>
						</div>
						{Summary === "weekly" ? (
							<div>
								<input
									type="week"
									value={Week}
									onChange={(e) => {
										setWeek(e.target.value);
										GetWeekTimestamps(e.target.value);
									}}
									onClick={(e) => e.target.showPicker()}
									className="rounded form-control"
								/>
							</div>
						) : Summary === "monthly" ? (
							<div>
								<input
									type="month"
									value={Month}
									onClick={(e) => e.target.showPicker()}
									onChange={(e) => {
										setMonth(e.target.value);
										GetMonthTimestamps(e.target.value);
									}}
									className="rounded form-control"
								/>
							</div>
						) : Summary === "annual" ? (
							<div>
								<select
									className="rounded  form-control p-1"
									value={Year}
									onChange={(e) => {
										setYear(e.target.value);
										GetYearTimestamps(e.target.value);
									}}
								>
									{Years.map((year) => (
										<option key={year} value={year}>
											{year}
										</option>
									))}
								</select>
							</div>
						) : (
							<>
								<div>
									<strong>From : </strong>
									<input
										onClick={(e) => e.target.showPicker()}
										type="date"
										className="rounded"
										value={FromDate}
										onChange={(e) => setFromDate(e.target.value)}
									/>
								</div>
								<div>
									<strong>To : </strong>{" "}
									<input
										onClick={(e) => e.target.showPicker()}
										type="date"
										className="rounded"
										value={ToDate}
										onChange={(e) => setToDate(e.target.value)}
										min={FromDate}
									/>
								</div>
							</>
						)}
					</div>
					<div className="card-body">
						<table className="table table-sm table-striped">
							<tr>
								<th>Rent Invoiced</th>
								<td>{filteredInvoices.reduce((a, b) => +a + +b.amount, 0)}</td>
							</tr>
							<tr>
								<th>Salaries</th>
								<td>
									-{FilteredPayrollEntries.reduce((a, b) => +a + +b.amount, 0)}
								</td>
							</tr>
							<tr>
								<th>Expenses</th>
								<td>-{Expenses.reduce((a, b) => +a + +b.amount, 0)}</td>
							</tr>
							<tr>
								<th className="text-decoration-underline">
									Expected Net Profit Before Tax
								</th>
								<td className="text-decoration-underline">
									{filteredInvoices.reduce((a, b) => +a + +b.amount, 0) -
										FilteredPayrollEntries.reduce((a, b) => +a + +b.amount, 0) -
										Expenses.reduce((a, b) => +a + +b.amount, 0)}
								</td>
							</tr>
							<tr>
								<th>Unpaid Invoices {`{Debts}`}</th>
								<td>
									-
									{filteredInvoices.reduce((a, b) => +a + +b.amount, 0) -
										filteredPayments.reduce((a, b) => +a + +b.amount, 0)}
								</td>
							</tr>
							<tr>
								<th className="text-decoration-underline">
									Realized Net Profit Before Tax
								</th>
								<td className="text-decoration-underline">
									{filteredPayments.reduce((a, b) => +a + +b.amount, 0) -
										FilteredPayrollEntries.reduce((a, b) => +a + +b.amount, 0) -
										Expenses.reduce((a, b) => +a + +b.amount, 0)}
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
			<GeneratePdf
				id="Profit&Loss"
				name={`Profi&Loss-${FromDate}-to-${ToDate}`}
			></GeneratePdf>
		</div>
	);
};

export default IncomeStatement;
