import React from "react";
import Purchase from "./Purchase2";
import { ArrowBarLeft } from "react-bootstrap-icons";
import MpesaPay from "./MpesaPay/MpesaPay";

const SubPay2 = ({ subs, updatePay, softwareId, amount, currency, rate }) => {
	return (
		<div>
			<p className="text-center text-capitalize h6 text-decoration-underline">
				<strong>Get Activation Code at :</strong>
			</p>
			<p className="text-center">
				{subs.length > 0 ? (
					`Activation Code if not already used ${subs[subs.length - 1].code}`
				) : (
					<p className="text-center h6 text-decoration-underline">
						<strong>NB: </strong>After Payment Click Go Back And Then Next For
						The Activation Code
					</p>
				)}
			</p>{" "}
			<div className=" row d-flex justify-content-center">
				<div className="col-md-6 ">
					<ul>
						<li>
							<div className="d-flex justify-content-between align-content-center">
								<span>
									{" "}
									<strong>
										One Time Pay <br />
										Forever Access :
									</strong>{" "}
									<br />
									{parseInt(
										(amount > 71.9 ? amount : 48) * 10 * (rate === 0 ? 1 : rate)
									) + (rate === 0 ? rate : 1)}{" "}
									{currency} Only{" "}
								</span>
								{currency === "KES" && rate !== 0 ? (
									<MpesaPay
										amount={
											parseInt(
												(amount > 71.9 ? amount : 48) *
													10 *
													(rate === 0 ? 1 : rate)
											) + (rate === 0 ? rate : 1)
										}
										type="lifetime"
										softwareId={softwareId}
										from={"software"}
									></MpesaPay>
								) : null}
							</div>
						</li>{" "}
						<li>
							<div className="d-flex justify-content-between">
								<span>
									{" "}
									<strong>Annual :</strong>
									<br />{" "}
									{parseInt(
										(amount > 71.9 ? amount : 48) * (rate === 0 ? 1 : rate)
									) + (rate === 0 ? rate : 1)}{" "}
									{currency} Only{" "}
								</span>
								<br />
								{currency === "KES" && rate !== 0 ? (
									<MpesaPay
										amount={
											parseInt(
												(amount > 71.9 ? amount : 48) * (rate === 0 ? 1 : rate)
											) + (rate === 0 ? rate : 1)
										}
										type="annual"
										softwareId={softwareId}
										from={"software"}
									></MpesaPay>
								) : null}
							</div>
						</li>{" "}
						<li className="mt-1">
							{" "}
							<div className="d-flex justify-content-between">
								<span>
									{" "}
									<strong>BiAnnual :</strong> <br />
									{parseInt(
										((amount > 71.9 ? amount : 48) / 2) *
											(rate === 0 ? 1 : rate)
									) + (rate === 0 ? rate : 1)}{" "}
									{currency} Only{" "}
								</span>
								{currency === "KES" && rate !== 0 ? (
									<MpesaPay
										amount={
											parseInt(
												((amount > 71.9 ? amount : 48) / 2) *
													(rate === 0 ? 1 : rate)
											) + (rate === 0 ? rate : 1)
										}
										type="biannual"
										softwareId={softwareId}
										from={"software"}
									></MpesaPay>
								) : null}
							</div>
						</li>{" "}
						<li className="mt-1">
							<div className="d-flex justify-content-between">
								<span>
									{" "}
									<strong>Quarterly :</strong> <br />
									{parseInt(
										((amount > 71.9 ? amount : 48) / 4) *
											(rate === 0 ? 1 : rate)
									) + (rate === 0 ? rate : 1)}{" "}
									{currency} Only
								</span>
								{currency === "KES" && rate !== 0 ? (
									<MpesaPay
										amount={
											parseInt(
												((amount > 71.9 ? amount : 48) / 4) *
													(rate === 0 ? 1 : rate)
											) + (rate === 0 ? rate : 1)
										}
										type="quaterly"
										softwareId={softwareId}
										from={"software"}
									></MpesaPay>
								) : null}
							</div>
						</li>{" "}
						<li className="mt-1">
							<div className="d-flex justify-content-between">
								<span>
									{" "}
									<strong>Monthly :</strong> <br />
									{parseInt(
										((amount > 71.9 ? amount : 48) / 12) *
											(rate === 0 ? 1 : rate)
									) + (rate === 0 ? rate : 1)}{" "}
									{currency} Only
								</span>
								{currency === "KES" && rate !== 0 ? (
									<MpesaPay
										amount={
											parseInt(
												((amount > 71.9 ? amount : 48) / 12) *
													(rate === 0 ? 1 : rate)
											) + (rate === 0 ? rate : 1)
										}
										type="monthly"
										softwareId={softwareId}
										from={"software"}
									></MpesaPay>
								) : null}
							</div>
						</li>
					</ul>
				</div>
			</div>
			{currency !== "KES" ? (
				<div className="row d-flex justify-content-center">
					<div className="col-md-12 justify-content-center">
						{subs.length > 0 ? (
							<Purchase
								softwareId={softwareId}
								amount={amount}
								from={"software"}
							></Purchase>
						) : (
							<Purchase
								softwareId={softwareId}
								amount={amount}
								from={"software"}
							></Purchase>
						)}
					</div>
				</div>
			) : null}
			<div className=" row d-flex justify-content-center">
				<div className="col-md-6 d-flex justify-content-around">
					<button
						className="btn btn-sm btn-outline-secondary"
						onClick={() => updatePay(false)}
					>
						<ArrowBarLeft></ArrowBarLeft> Go Back
					</button>
				</div>
			</div>
		</div>
	);
};

export default SubPay2;
